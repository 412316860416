import React, { FC, memo } from 'react';
import { useDisclosureInfo, useSelectAcknowledgement } from '../../hooks';
import './acknowledgement-list.scss';
import AcknowledgementList from './AcknowledgementList';
import { productTextSupplier } from '../../../../constants/constants';
import AcknowledgmentHeader from './AcknowledgmentHeader/AcknowledgmentHeader';
import { useAllProductCodes } from './hooks/useAllProductCodes';
import { useAllAcknowledgementsGroupedBySelectedProduct } from '../../hooks/useDisclosureInfo';

const AcknowledgementsByProduct: FC = () => {
  const { selectedAcknowledgement } = useDisclosureInfo();
  const selectHandler = useSelectAcknowledgement();
  const allRequestedProducts = useAllProductCodes();

  const acknowledgements = useAllAcknowledgementsGroupedBySelectedProduct();

  return (
    <div className={'disclosures__acknowledgement-list'}>
      {allRequestedProducts.map(pc => (
        <div key={pc}>
          <AcknowledgmentHeader header={productTextSupplier[pc || '']?.disclosureSubHeader} />
          <AcknowledgementList
            acknowledgements={acknowledgements[pc || ''] || []}
            selectHandler={selectHandler}
            selectedAcknowledgement={selectedAcknowledgement}
          />
        </div>
      ))}
    </div>
  );
};

export default memo(AcknowledgementsByProduct);
