import { useCallback, useEffect, useRef } from 'react';
import { PDFFileViewerContextProps } from '../types';

export type HolderReturn = {
  callback: (ctx: Partial<PDFFileViewerContextProps>) => void;
  data?: Partial<PDFFileViewerContextProps>;
};
export const useAllPagesRenderedCallback = (
  callback: (ctx: Partial<PDFFileViewerContextProps>) => void
) => {
  const pagesRenderedDataRef = useRef<HolderReturn>({ callback });

  useEffect(() => {
    pagesRenderedDataRef.current = { ...pagesRenderedDataRef.current, callback: callback };
    if (pagesRenderedDataRef.current.data) {
      callback(pagesRenderedDataRef.current.data);
    }
  }, [callback]);

  return useCallback<(ctx: Partial<PDFFileViewerContextProps>) => void>(ctx => {
    pagesRenderedDataRef.current = { ...pagesRenderedDataRef.current, data: ctx };
    if (pagesRenderedDataRef.current.data) {
      pagesRenderedDataRef.current.callback(pagesRenderedDataRef.current.data);
    }
  }, []);
};
