import React, { FC, useRef, useState } from 'react';
import { Document, Page } from 'react-pdf';
import { PageCallback } from 'react-pdf/dist/esm/shared/types';
import { PDF_DOC_OPTIONS } from './constants';
import { WrappedPromise } from './wrapped.promise';
import { generateUniqueID } from 'web-vitals/dist/modules/lib/generateUniqueID';

import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PDFFileViewerContextProps, PDFFileViewerProps } from './types';

import './styles.scss';
import { useSetPageWidthOnWindowResize } from './hooks/useObserveResize';
import { useDocumentContext } from './hooks/useDocumentContext';
import { usePublishOnAllPagesSuccess } from './hooks/usePublishOnAllPagesSuccess';

export const PDFFileViewer: FC<PDFFileViewerProps> = ({
  file,
  onAllPagesRendered = () => {},
}) => {
  const { pageDimensions, setPageDimensions, url, numPages, onDocLoadSuccess } =
    useDocumentContext(file);

  const { width, scale } = pageDimensions;

  const allPagesRenderedPromiseRef = useRef<WrappedPromise<PageCallback>[]>([]);

  const { docContainerRef, setContainerRef, toggleResizeLatch } =
    useSetPageWidthOnWindowResize(allPagesRenderedPromiseRef, setPageDimensions, scale, width, url);


  const { onPageRenderSuccess, onPageRenderError } = usePublishOnAllPagesSuccess(
    allPagesRenderedPromiseRef,
    ctx => {
      onAllPagesRendered({
        ...ctx,
        pdfUrl: url,
        setScale: (s: number, _width) => {
          if (!_width) toggleResizeLatch(false);
          setPageDimensions({ width: _width, scale: s });
        },
        viewerContainer: docContainerRef.current || document.createElement('div'),
      } as PDFFileViewerContextProps);
    },
    scale,
    numPages,
    width
  );

  const [{ msg }, setError] = useState({ docError: false, msg: '' });

  return (
    <div ref={setContainerRef} className={'oao-pdf-viewer u-l-full-height'}>
      <Document
        className="oao-pdf-viewer__Document"
        options={PDF_DOC_OPTIONS}
        renderMode={'canvas'}
        onLoadSuccess={onDocLoadSuccess}
        loading={<div>Loading</div>}
        file={url}
        error={msg}
        onLoadError={e => setError({ docError: true, msg: e.message })}
        onSourceError={e => setError({ docError: true, msg: e.message })}>
        {Array.from(new Array<number>(numPages), (_, index) => (
          <Page
            className="oao-pdf-viewer__Page"
            scale={scale}
            width={width}
            key={generateUniqueID()}
            pageNumber={index + 1}
            loading={<></>}
            error={<div className="u-flex">Page load error</div>}
            onRenderSuccess={onPageRenderSuccess}
            onRenderError={onPageRenderError(index + 1)}
          />
        ))}
      </Document>
    </div>
  );
};
