import { useApplicationInfo } from '../../../../ConfirmationPage/hooks';
import { useBundleInfo } from '../../../../BundleModal/hook/useBundleInfo';
import { useEffect, useState } from 'react';

export const useAllProductCodes = () => {
  const { productCode } = useApplicationInfo();
  const { productCodes } = useBundleInfo();

  const [allRequestedProducts, setAllRequestedProducts] = useState<string[]>([]);

  useEffect(() => {
    if (productCode) {
      setAllRequestedProducts([productCode, ...productCodes]);
    }
  }, [productCode, productCodes]);

  return allRequestedProducts;
};
