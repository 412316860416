import React, {
  ChangeEvent,
  FormEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FC } from 'react';
import { DropDownItemConfig, Icon } from '@laurelroad/lrds-react';
import { DropDownList } from '@laurelroad/lrds-react';
import { createPortal } from 'react-dom';
import { printFile } from '../../../../../../utils/print.file';
import { useFitToWidthHandler } from '../../hooks/useFitToWidthHandler';
import { PDFFileViewerContextProps } from '../../../../types';
import { useZoomControls } from '../../hooks/useZoomControls';

const HamburgerMenuList: FC<{
  items: DropDownItemConfig[];
  hideElement: () => void;
  top: number | 'unset';
}> = ({ items, top, hideElement }) => {
  return createPortal(
    <DropDownList
      animateIn
      style={{ top: top, marginTop: '20px', zIndex: '10', display: 'inline-block' }}
      items={items}
      isVisible={true}
      onClickOut={hideElement}
      selectedItemId={''}
    />,
    document.querySelector('.oao-pdf-viewer__toolbar')!
  );
};

export const Menu: FC<{
  pageIndex: number;
  numPages: number;
  scale: number;
  setScale: (s: number) => void;
  _pdfUrl?: string;
  pdfViewerCtxHolder: React.MutableRefObject<PDFFileViewerContextProps>;
  onPageChange: FormEventHandler<HTMLInputElement>;
}> = ({ onPageChange, pageIndex, numPages, scale, setScale, _pdfUrl, pdfViewerCtxHolder }) => {
  const [isOpen, toggleOpen] = useState(false);
  const hideElement = useCallback(() => toggleOpen(false), [toggleOpen]);

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [positionYMenu, setPositionYMenu] = useState<'unset' | number>('unset');

  const onClickFitToWidth = useFitToWidthHandler(pdfViewerCtxHolder);

  const { onZoomIn, onZoomOut } = useZoomControls(scale, setScale);

  const items: DropDownItemConfig[] = [
    {
      id: 'of',
      text: 'Open File',
      onClick: () => {
        window.open(_pdfUrl, '_blank');
      },
    },
    {
      id: 'p',
      text: 'Print',
      onClick: () => {
        if (_pdfUrl) printFile(_pdfUrl);
      },
    },
    {
      id: 'fw',
      text: 'Fit to Width',
      onClick: onClickFitToWidth,
    },
    {
      id: '100',
      text: '100%',
      onClick: () => {
        const { setScale } = pdfViewerCtxHolder.current;
        setScale(1);
      },
    },
    { id: 'zi', text: 'Zoom In', onClick: onZoomIn },
    { id: 'zo', text: 'Zoom Out', onClick: onZoomOut },
    {
      id: 'pu',
      text: 'Next Page',
      onClick: () =>
        onPageChange({
          currentTarget: {
            value: pageIndex + 1 <= numPages ? (pageIndex + 1).toString(10) : numPages,
          },
        } as ChangeEvent<HTMLInputElement>),
    },
    {
      id: 'pd',
      text: 'Previous Page',
      onClick: () =>
        onPageChange({
          currentTarget: {
            value: pageIndex - 1 >= 1 ? (pageIndex - 1).toString(10) : 1,
          },
        } as ChangeEvent<HTMLInputElement>),
    },
  ];

  useEffect(() => {
    if (isOpen) {
      setPositionYMenu(
        (buttonRef.current?.getClientRects().item(0)?.top || 0) -
          (buttonRef.current?.offsetParent?.getBoundingClientRect()?.top || 0) || 'unset'
      );
    }
  }, [isOpen]);

  return (
    <>
      <button
        ref={ref => {
          buttonRef.current = ref;
        }}
        type="button"
        style={{ position: 'relative' }}
        className="lrds-mobile-nav__menu-button"
        onClick={() => toggleOpen(!isOpen)}>
        <Icon icon="bars" className="lrds-mobile-nav__menu-button-icon" disablePointerEvents />
      </button>
      {isOpen && (
        <HamburgerMenuList hideElement={hideElement} top={positionYMenu} items={items} />
      )}
    </>
  );
};
