import {
  Acknowledgement,
  AcknowledgementDataViewDefinitionResponse,
  AcknowledgementDataViewFieldDefinition,
  AcknowledgementFileViewDefinitionResponse,
  BundleAcknowledgments,
  DisclosureInfoState,
} from './types';
import {
  DisclosureInfoCheckingSchema,
  DisclosureInfoLoyaltySchema,
  DisclosureInfoSavingSchema,
} from './constants';
import { cloneDeep } from 'lodash';
import Joi, { SchemaMap } from 'joi';
import { isCheckingProduct } from '../../constants/constants';

export const validateSavingsDisclosures = (disclosureInfo: DisclosureInfoState) => {
  const result = DisclosureInfoSavingSchema.validate(disclosureInfo);
  return !result.error;
};

export const validateCheckingDisclosures = (disclosureInfo: DisclosureInfoState) => {
  const result = DisclosureInfoCheckingSchema.validate(disclosureInfo);
  return !result.error;
};

export const validateLoyaltyDisclosures = (disclosureInfo: DisclosureInfoState) => {
  const result = DisclosureInfoLoyaltySchema.validate(disclosureInfo);
  return !result.error;
};

export const convertFetchDisclosuresToAcknowledgements = (
  acknowledgements: (
    | AcknowledgementFileViewDefinitionResponse
    | AcknowledgementDataViewDefinitionResponse
  )[]
) => {
  return acknowledgements.map(acknowledgement => {
    return cloneDeep(acknowledgement);
  });
};

export const validateAcknowledgement = (
  selectedAck: Acknowledgement,
  fieldValues: Record<string, any>
) => {
  if (selectedAck?.type !== 'DataView') {
    return true;
  }

  const schema = Joi.object(
    selectedAck.fields?.reduce(
      (acc, next) => {
        acc[next.name] = fieldTypeToJoiObject(next);
        return acc;
      },
      {} as SchemaMap<Record<string, any>>
    )
  ).options({ allowUnknown: true });

  const validationResult = schema.validate(fieldValues, {
    presence: 'required',
  });
  return !validationResult.error;
};

const fieldTypeToJoiObject = (ackFieldDef: AcknowledgementDataViewFieldDefinition) => {
  if (ackFieldDef.type === 'RadioGroup') {
    let schema = Joi.bool();
    if (ackFieldDef.required) {
      schema = schema.required();
    }
    return schema;
  } else {
    return Joi.any().optional().allow(undefined, null);
  }
};

export const filterAllGroupedByProductToBySelectedProduct = (
  acknowledgements: BundleAcknowledgments,
  allProducts: string[]
) => {
  const allAddedAcks = new Set<string>();
  return allProducts.reduce((acc, pc) => {
    acc[pc] = acknowledgements[pc]
      ? acknowledgements[pc]
          .concat(isCheckingProduct(pc) ? acknowledgements['noCode'] : [])
          .sort((l, r) => l.order - r.order)
      : isCheckingProduct(pc)
      ? acknowledgements['noCode']
      : [];
    acc[pc] = acc[pc].filter(a => {
      if (allAddedAcks.has(a.id)) {
        return false;
      }
      allAddedAcks.add(a.id);
      return true;
    });
    return acc;
  }, {} as BundleAcknowledgments);
};
