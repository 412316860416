import { useEffect, useRef, useState } from 'react';
import { PDFViewerObserver } from '../../../observer';
import { PDFFileViewerContextProps } from '../../../types';
import { INITIAL_PDF_VIEWER_STATE } from '../../../constants';

export const useSubscribeToPDFFileViewer = (
  viewerObserver: PDFViewerObserver<PDFFileViewerContextProps>,
  setNumPages: (totalPages: number) => void,
  pageIndex: number
) => {
  const [_scale, _setScale] = useState(1);
  const [_pdfUrl, set_pdfUrl] = useState<string | undefined>();

  const pdfViewerCtxHolder = useRef<PDFFileViewerContextProps>(INITIAL_PDF_VIEWER_STATE);

  useEffect(() => {
    const unregister = viewerObserver.register(data => {
      pdfViewerCtxHolder.current = data;
      setNumPages(data.totalPages);

      const { pagesInfo, pdfUrl } = data;
      const currentPageInfo = pagesInfo[pageIndex - 1] || {
        originalWidth: 1,
        width: 1,
      };
      _setScale(currentPageInfo.width / currentPageInfo.originalWidth);
      set_pdfUrl(pdfUrl);
    });

    return () => {
      unregister();
    };
  }, [pageIndex, setNumPages, viewerObserver]);

  return {
    _scale,
    _pdfUrl,
    pdfViewerCtxHolder,
  };
};
