import React, { FC, memo } from 'react';
import { Acknowledgement } from '../../types';
import './acknowledgement-viewer.scss';
import { AcknowledgementViewerAccept } from '../AcknowledgmentViewerAccept/AcknowledgementViewerAccept';
import { acknowledgementViewerComponentFactory } from '../acknowledgementViewerComponentFactory';
import { useDataViewInputContext } from '../../hooks/useDataViewInputContext';

const AcknowledgementViewer: FC<{
  acknowledgement: Acknowledgement | null;
}> = ({ acknowledgement }) => {
  const { setCustomAckIcon, setDataViewFieldValue, fieldValues } = useDataViewInputContext();

  return (
    <div
      className="u-flex u-flex--column disclosures-viewer-u-border-radius"
      style={{
        flex: '1 0 48.5%',
        maxWidth: '48.5%',
        height: acknowledgement?.type === 'FileView' ? '500px' : '100%',
        border:
          acknowledgement?.type === 'FileView' ? '1px solid rgba(0, 0, 0, 0.3)' : 'unset',
      }}>
      {acknowledgementViewerComponentFactory(
        acknowledgement,
        'desktop',
        setDataViewFieldValue,
        setCustomAckIcon,
        fieldValues
      )}
      <AcknowledgementViewerAccept />
    </div>
  );
};

export default memo(AcknowledgementViewer);
