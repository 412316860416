import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Button, Column, Grid, GridBreakpoints, LrIconName, Optional } from '@laurelroad/lrds-react';
import './styles.scss';
import { useIsMobile } from '../../hooks/useIsMobile';
import GovernmentId from './GovernmentId/GovernmentId';
import Occupation from './Occupation/Occupation';
import Education from './Education/Education';
import Income from './Income/Income';
import Citizenship from './Citizenship/Citizenship';
import ForeignGovernmentDisclosure from './ForeignGovernmentDisclosure/ForeignGovernmentDisclosure';
import SourcesOfOngoingAccountFunding from './SourcesOfOngoingAccountFunding/SourcesOfOngoingAccountFunding';
import AccountPurpose from './AccountPurpose/AccountPurpose';
import PersonalInfoModal from './PersonalInfoModal/PersonalInfoModal';
import {
  usePersonalInfo,
  usePersonalInfoViewState,
  useSubmitPersonalInfoCallback,
} from './hooks';
import { OAOForm } from '../OAOForm/OAOForm';
import { useSendPersonalInfoEvent } from '../../services/google-tag-manager/useSendPersonalInfoEvent';
import BundleModal from '../BundleModal/BundleModal';
import { useDispatch } from 'react-redux';
import { showPersonalFieldErrors } from './slice';
import { autoScrollToError } from '../../utils/autoScrollToError';
import { validatePersonalFields } from './asyncActions';

const PersonalInfoPage: FC = () => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch();
  const personalInfo = usePersonalInfo();
  const continueButtonClickHandler = useSubmitPersonalInfoCallback();
  const { showFieldErrors, personalFieldErrors } = usePersonalInfoViewState();

  useSendPersonalInfoEvent();

  const personalInfoHandler = useCallback(() => {
    dispatch(showPersonalFieldErrors());
  }, [dispatch]);

  const gridBreakpoints: GridBreakpoints[] = isMobile
    ? [{ breakpoint: 'mobile', autoSize: 'sm' }]
    : [];

  const headerData = {
    icon: 'id-badge' as LrIconName,
    text: "We'll finish up your application with the following information.",
  };

  useEffect(() => {
    if (showFieldErrors && personalFieldErrors.length > 0) {
      autoScrollToError(personalFieldErrors);
    }
  }, [personalFieldErrors, showFieldErrors]);

  useEffect(() => {
    window.scrollTo(0,0);
  }, []);

  useEffect( () => {
    dispatch(validatePersonalFields(personalInfo));
  },[dispatch, personalInfo]);

  return (
    <OAOForm
      className={'personal-info'}
      headerData={headerData}
      data-testid="personal-info-page">
      <Grid className={''} gap={'md'} breakpoints={gridBreakpoints}>
        <Optional renderIf={!personalFieldErrors.length}>
          <BundleModal />
        </Optional>
        <PersonalInfoModal />
        <GovernmentId />
        <Education />
        <Income />
        <Occupation />
        <AccountPurpose />
        <SourcesOfOngoingAccountFunding />
        <Column size={9} />
        <Citizenship />
        <ForeignGovernmentDisclosure />
        <Column size={9} />
        <Column size={3} className={'personal-info__footer'}>
          <Button
            onClick={() => {
              if (personalFieldErrors.length > 0) {
                personalInfoHandler();
              } else {
                continueButtonClickHandler();
              }
            }}>
            Continue
          </Button>
        </Column>
      </Grid>
    </OAOForm>
  );
};

export default memo(PersonalInfoPage);
