import React, { FC } from 'react';
import { useZoomControls } from '../../hooks/useZoomControls';

const PlusIcon: FC = () => {
  return <img src="/images/plus.svg" alt={'*'} />;
};

const MinusIcon: FC = () => {
  return <img src="/images/minus.svg" alt={'*'} />;
};

export const ToolbarZoomControl: FC<{
  scale: number;
  setScale: (s: number) => void;
}> = ({ scale, setScale }) => {
  const { zoom, onZoomIn, onZoomOut } = useZoomControls(scale, setScale);

  return (
    <div className="u-flex--between u-flex oao-pdf-viewer__toolbar__zoom">
      <div
        style={{ cursor: 'pointer' }}
        onClick={onZoomOut}
        className="oao-pdf-viewer__toolbar__zoom__direction">
        <MinusIcon />
      </div>
      <div className="u-flex u-flex__item--middle">{`${zoom}%`}</div>
      <div
        style={{ cursor: 'pointer' }}
        onClick={onZoomIn}
        className="oao-pdf-viewer__toolbar__zoom__direction">
        <PlusIcon />
      </div>
    </div>
  );
};
