export class WrappedPromise<T> implements PromiseLike<T> {
  private _resolve: (value: T | PromiseLike<T>) => void = () => {};
  private _reject: (reason?: any) => void = () => {};

  private _delegate: Promise<T>;
  private isResolved = false;

  constructor() {
    this._delegate = new Promise<T>((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
  }

  public doResolve = (value: T) => {
    this.isResolved = true;
    this._resolve(value);
  };

  public doReject = (reason: any) => {
    if (!this.isResolved) {
      this._reject(reason);
    }
  };

  then<TResult1 = T, TResult2 = never>(
    onfulfilled?: ((value: T) => PromiseLike<TResult1> | TResult1) | undefined | null,
    onrejected?: ((reason: any) => PromiseLike<TResult2> | TResult2) | undefined | null
  ): PromiseLike<TResult1 | TResult2> {
    return this._delegate.then(onfulfilled, onrejected);
  }
}
