import React, { FC, memo } from 'react';
import { Loader, Optional, responsiveComponent } from '@laurelroad/lrds-react';
import './disclosures-body.scss';
import AcknowledgementViewer from './AcknowledgementViewer/AcknowledgementViewer';
import AcknowledgementsByProduct from './AcknowledgementList/AcknowledgementsByProduct';
import AcknowledgementViewerMobile from './AcknowledgementViewerMobile/AcknowledgementViewerMobile';
import { useDisclosuresBodyState } from '../hooks/useDisclosureInfo';

const Viewer = responsiveComponent(AcknowledgementViewerMobile, AcknowledgementViewer);
const DisclosuresBody: FC = () => {
  const { isLoading, selectedAcknowledgement } = useDisclosuresBodyState();

  return (
    <div className={'disclosures__body'}>
      <Optional renderIf={isLoading}>
        <Loader />
      </Optional>
      <Optional renderIf={!isLoading}>
        <div className="u-flex disclosures__body__inner-container u-l-full-width">
          <AcknowledgementsByProduct />
          <Viewer acknowledgement={selectedAcknowledgement} />
        </div>
      </Optional>
    </div>
  );
};

export default memo(DisclosuresBody);
