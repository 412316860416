import {
  LegacyRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';

export const useSetPageWidthOnWindowResize = <T extends PromiseLike<any>>(
  allPagesRenderedPromiseRef: MutableRefObject<T[]>,
  setPageDimensions: (p: any) => void,
  scale: number,
  width?: number,
  file?: string
): {
  setContainerRef: LegacyRef<HTMLDivElement> | undefined;
  docContainerRef: MutableRefObject<HTMLDivElement | null>;
  toggleResizeLatch: (enable: boolean) => void;
} => {
  const observerLatchRef = useRef(true);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const resizingDelayTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      observerLatchRef.current = true;
      const observer = new ResizeObserver(entries => {
        if (resizingDelayTimer.current) clearTimeout(resizingDelayTimer.current);

        resizingDelayTimer.current = setTimeout(() => {
          const [entry] = entries;
          if (entry && observerLatchRef.current) {
            Promise.all(allPagesRenderedPromiseRef.current)
              .then(() => {
                setPageDimensions({ width: entry.contentRect.width - 20, scale: 1 });
              })
              .catch(() => {}); // 20 is css side margins
          }
        }, 100);
      });
      const _container = containerRef.current;
      observer.observe(_container, { box: 'border-box' });

      return () => {
        observer.unobserve(_container);
        if (resizingDelayTimer.current) clearTimeout(resizingDelayTimer.current);
      };
    }
  }, [allPagesRenderedPromiseRef, file, setPageDimensions]);

  useLayoutEffect(() => {
    Promise.all(allPagesRenderedPromiseRef.current)
      .then(() => {
        observerLatchRef.current = true;
      })
      .catch(() => {
        observerLatchRef.current = true;
      });
  }, [allPagesRenderedPromiseRef, scale]);

  useEffect(() => {
    if (!width) observerLatchRef.current = true;
  }, [width]);

  const onBeforeScaleChange = useCallback((enable: boolean) => {
    observerLatchRef.current = enable;
  }, []);

  return {
    docContainerRef: containerRef,
    setContainerRef: (ref: HTMLDivElement | null) => {
      containerRef.current = ref;
    },
    toggleResizeLatch: onBeforeScaleChange,
  };
};
