import React, { FC, useCallback, useRef } from 'react';
import { PDFViewerToolbar } from '../../../../../PdfFileViewer/components/PDFViewerToolbar/PDFViewerToolbar';
import { PDFFileViewer } from '../../../../../PdfFileViewer/PDFFileViewer';
import { PDFViewerObserver } from '../../../../../PdfFileViewer/observer';
import { PDFFileViewerContextProps } from '../../../../../PdfFileViewer/types';
import { Acknowledgement } from '../../../../types';

export const FileAcknowledgementViewer: FC<{
  acknowledgement: Acknowledgement | null;
}> = ({ acknowledgement }) => {
  const file = acknowledgement ? acknowledgement.location! : '';

  const pdfChangeObserverRef = useRef(new PDFViewerObserver<PDFFileViewerContextProps>());

  const pdfViewerCallback = useCallback((ctx: PDFFileViewerContextProps) => {
    pdfChangeObserverRef.current.publish(ctx);
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: '#D1DAE5',
          padding: '8px 16px 8px 16px',
          borderRadius: '8px 8px 0 0',
          overflowX: 'hidden',
          width: '100%',
          height: 'auto',
          flex: 'auto',
          display: 'flex',
        }}>
        <PDFViewerToolbar viewerObserver={pdfChangeObserverRef.current} />
      </div>
      <PDFFileViewer file={file} onAllPagesRendered={pdfViewerCallback} />
    </>
  );
};
