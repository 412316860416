import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { acceptValidatedAcknowledgement } from '../asyncActions';

export const useAcceptAcknowledgement = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(acceptValidatedAcknowledgement());
  }, [dispatch]);
};
