import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { submitPersonalInfo } from "../asyncActions";

export const useSubmitPersonalInfoCallback = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(submitPersonalInfo());
  }, [dispatch]);
};
