import { AVAILABLE_SCALES } from '../../constants';

export const findNextScale = (currentScale: number, isZoomIn: boolean) => {
  const index = AVAILABLE_SCALES.indexOf(currentScale);
  if (index !== -1) {
    let newIndex = 0;
    if ((newIndex = index + (isZoomIn ? 1 : -1)) < AVAILABLE_SCALES.length && newIndex >= 0) {
      return AVAILABLE_SCALES[newIndex];
    } else return currentScale;
  }
  return AVAILABLE_SCALES.find((_scale, idx) => {
    if (isZoomIn) {
      return (
        Math.min(_scale, Math.min(currentScale, AVAILABLE_SCALES[idx + 1] || _scale)) ===
        currentScale
      );
    }
    return (
      Math.max(_scale, Math.max(currentScale, AVAILABLE_SCALES[idx + 1] || _scale)) !==
      currentScale
    );
  }) || currentScale;
};
