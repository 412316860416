import { jsonHeaders } from './utils/crud/constants';

const fetchUiConfigs = (): Promise<string[]> => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  return fetch('/public/api/ui-configuration', {
    method: 'PUT',
    headers: jsonHeaders(),
    body: JSON.stringify({ ...queryParams, referrer: window.document.referrer }),
  })
    .then(response => {
      if (!response.ok) {
        return Promise.reject({
          status: response.status,
          message: 'GET request ui-configs failed',
        });
      }
      return response.json();
    })
    .then(uiConfigs => {
      document.getElementsByName('_heap-id')[0].setAttribute('content', uiConfigs.heapId);
      document
        .getElementsByName('_ibx-redirect-url')[0]
        .setAttribute('content', uiConfigs.ibxRedirectUrl);
      document
        .getElementsByName('_idv-api-url')[0]
        .setAttribute('content', uiConfigs.idvApiUrl);
      document
        .getElementsByName('_csrf_header')[0]
        .setAttribute('content', uiConfigs.csrfHeaderName || '');
      document.getElementsByName('_csrf')[0].setAttribute('content', uiConfigs.csrfToken);
      document.getElementsByName('_amt_mock')[0].setAttribute('content', uiConfigs.mockAmt);

      window.cmsApiUrl = uiConfigs.cmsApiUrl;
      window.gradFinUrl = uiConfigs.gradFinUrl;
      return uiConfigs.optionalFields;
    })
    .catch(e => {
      console.error(e.message);
      document.getElementsByName('_amt_mock')[0].setAttribute('content', 'false');
    });
}

function loadAmt() {
  return new Promise(resolve => {
    let cssFiles = [
      'https://laurelroad.key.com/ibxolb/styles/bts-playground-base.css',
      'https://laurelroad.key.com/ibxolb/styles/kds-base.css',
      'https://laurelroad.key.com/ibxolb/styles/ibx-base.css',
      'https://laurelroad.key.com/ibxolb/amt-tkt/amt-sdk/web/styles.css',
      'https://laurelroad.key.com/ibxolb/amt-tkt/amt-ui-shell/styles-lrd.css',
    ];
    let jsFiles = [
      'https://laurelroad.key.com/ibxolb/platform-tkt/bundle.js',
      'https://laurelroad.key.com/ibxolb/common-tkt/bundle.js',
      'https://laurelroad.key.com/ibxolb/amt-tkt/amt-sdk/web/bundle.js',
      'https://laurelroad.key.com/ibxolb/amt-tkt/amt-service/bundle.js',
      'https://ci-mpsnare.iovation.com/snare.js',
    ];

    const mock =
      document.getElementsByName('_amt_mock')[0]?.getAttribute('content') || 'false';
    if (mock === 'true') {
      console.info('load mock amt');
      jsFiles = ['/scripts/amt_mock.js'];
      cssFiles = [];
    } else {
      console.info('load amt');
    }

    const head = document.head;
    cssFiles.forEach(file => {
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = file;

      head.insertAdjacentElement("afterbegin",link);
    });

    jsFiles.forEach(file => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = file;

      head.appendChild(script);
    });

    resolve({});
  });
}

export const onAppBoot = () =>
    fetchUiConfigs().then(async results => {
      await loadAmt();
      return results;
    });
