import { useSelector } from 'react-redux';
import { AppState } from '../../../store';
import { BundleAcknowledgments, DisclosureInfoState } from '../types';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { filterAllGroupedByProductToBySelectedProduct } from '../utils';

export const useDisclosureInfo = () => {
  return useSelector<AppState, DisclosureInfoState>(({ disclosureInfo }) => ({
    ...disclosureInfo,
  }));
};

export const useDisclosuresBodyState = () => {
  return useSelector<
    AppState,
    Pick<DisclosureInfoState, 'selectedAcknowledgement'> & { isLoading: boolean }
  >(({ disclosureInfo }) => ({
    selectedAcknowledgement: disclosureInfo.selectedAcknowledgement,
    isLoading: isEmpty(disclosureInfo.acknowledgements),
  }));
};

export const useAllAcknowledgementsGroupedBySelectedProduct = () => {
  const allAvailableAcknowledgements = useSelector<
    AppState,
    { acknowledgements: BundleAcknowledgments; bundleCodes: string[]; primaryAppCode: string }
  >(
    ({ application: { productCode }, disclosureInfo, bundle: { productCodes } }) => ({
      acknowledgements: disclosureInfo.acknowledgements,
      bundleCodes: productCodes,
      primaryAppCode: productCode,
    }),
    ({ bundleCodes, acknowledgements }, right) =>
      bundleCodes === right.bundleCodes && acknowledgements === right.acknowledgements
  );

  return useMemo(() => {
    const { acknowledgements, primaryAppCode, bundleCodes } = allAvailableAcknowledgements;

    return filterAllGroupedByProductToBySelectedProduct(acknowledgements, [
      primaryAppCode,
      ...bundleCodes,
    ]);
  }, [allAvailableAcknowledgements]);
};
