import { Icon, LrIconName, Optional, useCn } from '@laurelroad/lrds-react';
import React, { FunctionComponent, memo } from 'react';
import './acknowledgement-row.scss';
import { AcknowledgementRowProps } from './types';
import { useDisclosureInfo } from '../../../hooks';
import { FaIconName } from '@laurelroad/lrds-react/src/icons';
import { AcknowledgementRowActions } from './components/AcknowledgementRowActions/AcknowledgementRowActions';

const AcknowledgementRow: FunctionComponent<AcknowledgementRowProps> = ({
  acknowledgement,
  onClick,
  isActiveRow,
}) => {
  const { displayName, location } = acknowledgement;

  const { notAcceptedAcknowledgements, customDataViewAcknowledgementAcceptedIcon } =
    useDisclosureInfo();

  const dependencies = acknowledgement.dependencies || [];

  const someDependentNotAccepted = dependencies.some(
    id => notAcceptedAcknowledgements[id] !== undefined
  );

  const classes = useCn('disclosures__acknowledgement-list__row', {
    'disclosures__acknowledgement-list__row--disabled': someDependentNotAccepted,
    'disclosures__acknowledgement-list__row--active': isActiveRow,
  });

  return (
    <div className={classes}>
      <div
        className="display-flex"
        style={{ maxWidth: '80%' }}
        onClick={() => {
          onClick(acknowledgement);
        }}>
        <div className={`disclosures__acknowledgement-list__row__item-name`}>
          <span className="lrds-text" data-color="primary">
            {displayName}
          </span>
        </div>
      </div>
      <div className="disclosures__acknowledgement-list__row__icons">
        <Optional renderIf={notAcceptedAcknowledgements[acknowledgement.id] === undefined}>
          {acknowledgement?.type === 'DataView' && acknowledgement?.providesCustomAckIcon ? (
            <Icon
              className="common-icon fa-lg"
              icon={
                (customDataViewAcknowledgementAcceptedIcon[acknowledgement?.id] || 'check') as
                  | LrIconName
                  | FaIconName
              }
              style={{ maxWidth: '16px' }}
            />
          ) : (
            <AcknowledgementRowActions location={location} displayName={displayName} />
          )}
        </Optional>
      </div>
    </div>
  );
};

export default memo(AcknowledgementRow);
