import { useCallback, useEffect, useState } from 'react';
import { INIT_PAGE_DIMENSIONS } from '../constants';
import { OnDocumentLoadSuccess } from 'react-pdf/src/shared/types';

export const useDocumentContext = (file?: string) => {
  const [numPages, setNumPages] = useState(0);

  const [_file, set_file] = useState(file);

  const [pageDimensions, setPageDimensions] = useState<{ width?: number; scale: number }>(
    INIT_PAGE_DIMENSIONS
  );

  // reset on file change
  useEffect(() => {
    setNumPages(0);
    setPageDimensions(INIT_PAGE_DIMENSIONS);
    set_file(file);
  }, [file]);

  const onDocLoadSuccess = useCallback<OnDocumentLoadSuccess>(cb => {
    setNumPages(cb.numPages);
  }, []);

  return {
    url: _file,
    numPages,
    pageDimensions,
    onDocLoadSuccess,
    setPageDimensions,
  };
};
