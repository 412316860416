import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { debounce } from 'lodash';
import { PDFFileViewerContextProps } from '../../../types';
import { INITIAL_PDF_VIEWER_STATE } from '../../../constants';

export const useScrollListenerToSetPage = (
  pdfViewerCtxHolder: MutableRefObject<PDFFileViewerContextProps>,
  setPageIndex: (p: number) => void,
  isDisableScrollListenerSupplier: () => boolean
) => {
  const setPageDebounced = useMemo(() => debounce(setPageIndex, 200), [setPageIndex]);

  useEffect(() => {
    return () => setPageDebounced.cancel();
  }, [setPageDebounced]);

  const scrollListener = useCallback(
    ({ target }: Event) => {
      if (isDisableScrollListenerSupplier()) return;
      const { pagesInfo } = pdfViewerCtxHolder.current;
      let yPos = 0;
      let page = 0;
      const currentScrollPosition = (target as any).scrollTop;
      for (let i = 0; yPos <= currentScrollPosition && i < pagesInfo.length; i++) {
        yPos += Math.floor(pagesInfo[i]?.height) + 4;
        page++;
      }
      setPageDebounced.cancel();
      setPageDebounced(page === 0 ? 1 : page);
    },
    [pdfViewerCtxHolder, setPageDebounced, isDisableScrollListenerSupplier]
  );

  const { viewerContainer } = pdfViewerCtxHolder.current;

  useEffect(() => {
    if (viewerContainer === INITIAL_PDF_VIEWER_STATE.viewerContainer) {
      return;
    }

    const _scrollListener = scrollListener;
    const _oldContainer = viewerContainer;

    viewerContainer?.addEventListener('scroll', scrollListener);

    return () => {
      _oldContainer?.removeEventListener('scroll', _scrollListener);
    };
  }, [scrollListener, viewerContainer]);
};
