export class PDFViewerObserver<T> {
  private listener: (data: T) => void;
  constructor() {
    this.listener = () => {};
  }

  public register = (listener: (data: T) => void): (() => void) => {
    this.listener = listener;
    return () => {
      this.listener = () => {};
    };
  };

  public publish = (data: T) => {
    this.listener(data);
  };
}
