import { useEffect, useState } from 'react';
import { useDisclosureInfo } from '../../../hooks';

export const useDrawerControl = (): [boolean, () => void] => {
  const [isOpen, toggle] = useState(false);

  const { notAcceptedAcknowledgements, viewedAcknowledgements, selectedAcknowledgement } =
    useDisclosureInfo();

  useEffect(() => {
    toggle(true);
  }, [selectedAcknowledgement, viewedAcknowledgements]);

  const allAcknowledged = Object.values(notAcceptedAcknowledgements).length === 0;

  // this one just close the drawer once all accepted the first time. They can still re-open after that.
  useEffect(() => {
    if (allAcknowledged) {
      toggle(false);
    }
  }, [allAcknowledged]);

  return [isOpen, () => toggle(false)];
};
