import { ProductCode } from './ProductCode';

export const NAVIGATION_BAR_SAVINGS_TITLE = 'Laurel Road High-Yield Savings®';
export const NAVIGATION_BAR_BUNDLE_TITLE = 'Laurel Road Linked Savings℠';
export const NAVIGATION_BAR_STANDALONE_CHECKING_TITLE =
  'Laurel Road Checking℠ with Debit Card';
export const NAVIGATION_BAR_LINKED_CHECKING_TITLE =
  'Laurel Road Linked Checking℠ with Debit Card';
export const NAVIGATION_BAR_LOYALTY_CHECKING_TITLE =
  'Laurel Road Loyalty Checking℠ with Debit Card';
export const NAVIGATION_BAR_FREEDOM_CHECKING_TITLE =
  'Laurel Road Freedom Plus Checking℠ with Debit Card';

export const SAVINGS_ACCOUNT_NAME = 'Savings Account Details';
export const CHECKING_ACCOUNT_NAME = 'Checking Account Details';

export const SAVINGS_PRODUCT_NAME = 'Laurel Road High-Yield Savings®';
export const BUNDLED_SAVINGS_PRODUCT_NAME = 'Laurel Road Linked Savings℠';
export const CHECKING_PRODUCT_NAME = 'Laurel Road Checking℠';
export const LOYALTY_CHECKING_NAME = 'Laurel Road Loyalty Checking℠';
export const LINKED_CHECKING_PRODUCT_NAME = 'Laurel Road Linked Checking℠';
export const FREEDOM_CHECKING_PRODUCT_NAME = 'Laurel Road Freedom Plus Checking℠';

export const SAVINGS_DISCLOSURE_NAME = 'High Yield Savings Disclosures';
export const CHECKING_DISCLOSURE_NAME = 'Checking Disclosures';

export interface ProductText {
  headerTitle: string;
  accountDetailsHeaderTitle: string;
  productName: string;
  disclosureSubHeader: string;
}

interface ProductTextSupplier {
  [key: string]: {
    headerTitle: string;
    accountDetailsHeaderTitle: string;
    productName: string;
    disclosureSubHeader: string;
  };
}

export const productTextSupplier: ProductTextSupplier = {
  [ProductCode.CHECKING]: {
    headerTitle: NAVIGATION_BAR_STANDALONE_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.BUNDLE_CHECKING]: {
    headerTitle: NAVIGATION_BAR_LINKED_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: LINKED_CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.LOYALTY_CHECKING]: {
    headerTitle: NAVIGATION_BAR_LOYALTY_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: LOYALTY_CHECKING_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.PSLF_CHECKING]: {
    headerTitle: NAVIGATION_BAR_FREEDOM_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: FREEDOM_CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.IDR_CHECKING]: {
    headerTitle: NAVIGATION_BAR_FREEDOM_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: FREEDOM_CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.BUNDLE_IDR_CHECKING]: {
    headerTitle: NAVIGATION_BAR_FREEDOM_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: FREEDOM_CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.BUNDLE_PSLF_CHECKING]: {
    headerTitle: NAVIGATION_BAR_FREEDOM_CHECKING_TITLE,
    accountDetailsHeaderTitle: CHECKING_ACCOUNT_NAME,
    productName: FREEDOM_CHECKING_PRODUCT_NAME,
    disclosureSubHeader: CHECKING_DISCLOSURE_NAME,
  },
  [ProductCode.SAVINGS]: {
    headerTitle: NAVIGATION_BAR_SAVINGS_TITLE,
    accountDetailsHeaderTitle: SAVINGS_ACCOUNT_NAME,
    productName: SAVINGS_PRODUCT_NAME,
    disclosureSubHeader: SAVINGS_DISCLOSURE_NAME,
  },
  [ProductCode.BUNDLED_SAVINGS]: {
    headerTitle: NAVIGATION_BAR_BUNDLE_TITLE,
    accountDetailsHeaderTitle: SAVINGS_ACCOUNT_NAME,
    productName: BUNDLED_SAVINGS_PRODUCT_NAME,
    disclosureSubHeader: SAVINGS_DISCLOSURE_NAME,
  },
};

export const isCheckingProduct = (code: string) => {
  return (
    code === ProductCode.BUNDLE_CHECKING ||
    code === ProductCode.CHECKING ||
    code === ProductCode.LOYALTY_CHECKING ||
    code === ProductCode.IDR_CHECKING ||
    code === ProductCode.PSLF_CHECKING ||
    code === ProductCode.BUNDLE_IDR_CHECKING ||
    code === ProductCode.BUNDLE_PSLF_CHECKING
  );
};
