import { useAllPagesRenderedCallback } from './useAllPagesRenderedCallback';
import { MutableRefObject, useCallback, useLayoutEffect } from 'react';
import { WrappedPromise } from '../wrapped.promise';
import { OnRenderError, PageCallback } from 'react-pdf/dist/esm/shared/types';
import { PDFFileViewerContextProps } from '../types';

export const usePublishOnAllPagesSuccess = (
  allPagesRenderedPromiseRef: MutableRefObject<WrappedPromise<PageCallback>[]>,
  onAllPagesRendered: (ctx: Partial<PDFFileViewerContextProps>) => void,
  scale: number,
  numPages: number,
  width?: number
) => {
  const onAllPagesRenderedCallback = useAllPagesRenderedCallback(onAllPagesRendered);

  useLayoutEffect(() => {
    allPagesRenderedPromiseRef.current = Array.from(new Array(numPages), () => {
      return new WrappedPromise<PageCallback>();
    });

    Promise.allSettled(allPagesRenderedPromiseRef.current)
      .then(
        settled => {
          if (settled.some(page => page.status !== 'fulfilled')) {
            return;
          }

          const pagesInfo = settled.map(
            pageResolved => (pageResolved as PromiseFulfilledResult<PageCallback>).value
          );

          onAllPagesRenderedCallback({
            totalPages: numPages,
            pagesInfo,
          });
        },
        () => {
          // noop
        }
      )
      .catch(() => {
        //noop
      });

    return () => {
      allPagesRenderedPromiseRef.current.forEach(pr => pr.doReject('stale loading cleanup'));
    };
  }, [scale, width, numPages, onAllPagesRenderedCallback, allPagesRenderedPromiseRef]);

  const onPageRendered = useCallback<
    (
      page: Pick<
        PageCallback,
        'pageNumber' | 'width' | 'originalWidth' | 'height' | 'originalHeight'
      >
    ) => void
  >(
    page => {
      const pageNumber = page.pageNumber;

      allPagesRenderedPromiseRef.current[pageNumber - 1]?.doResolve({
        pageNumber,
        width: page.width,
        height: page.height,
        originalWidth: page.originalWidth,
        originalHeight: page.originalHeight,
      } as PageCallback);
    },
    [allPagesRenderedPromiseRef]
  );

  const onPageError = useCallback<(index: number) => OnRenderError>(
    pageNumber => {
      return () => {
        allPagesRenderedPromiseRef.current[pageNumber - 1]?.doReject(
          `Failed to render ${pageNumber}`
        );
      };
    },
    [allPagesRenderedPromiseRef]
  );

  return {
    onPageRenderSuccess: onPageRendered,
    onPageRenderError: onPageError,
  };
};
