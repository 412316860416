import React, { FC } from 'react';
import { AcknowledgmentListProps } from '../../types';
import AcknowledgementRow from './AcknowledgementRow/AcknowledgementRow';

const AcknowledgementList: FC<AcknowledgmentListProps> = ({
  acknowledgements,
  selectHandler,
  selectedAcknowledgement,
}) => {
  return (
    <>
      {acknowledgements.map((acknowledgement) => (
        <AcknowledgementRow
          acknowledgement={acknowledgement}
          key={acknowledgement.id}
          onClick={selectHandler}
          isActiveRow={acknowledgement?.id === selectedAcknowledgement?.id}
        />
      ))}
    </>
  );
};

export default AcknowledgementList;
