import { MutableRefObject, useEffect } from 'react';
import { PDFFileViewerContextProps } from '../../../types';

export const useScrollOnPageChangeFromPageInput = (
  pageIndex: number,
  pdfViewerCtxHolder: MutableRefObject<PDFFileViewerContextProps>,
  isScrollAllowedSupplier: () => boolean
) => {
  useEffect(() => {
    if (isScrollAllowedSupplier() && pageIndex !== 0) {
      const { pagesInfo, viewerContainer } = pdfViewerCtxHolder.current;

      let yPos = 0;

      for (let i = 0; i < pageIndex - 1 && i < pagesInfo.length; i++) {
        yPos += Math.floor(pagesInfo[i].height) + 4; // 4 is in px gap between pages
      }

      viewerContainer?.scrollTo({ top: yPos, behavior: 'instant' });
    }
  }, [pageIndex, pdfViewerCtxHolder, isScrollAllowedSupplier]);
};
