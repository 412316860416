export interface Acknowledgement
  extends AcknowledgementFileViewDefinitionResponse,
    AcknowledgementDataViewDefinitionResponse {}

export type AcknowledgementDefinitionResponse = {
  id: string;
  displayName: string;
  type: 'FileView' | 'DataView';
  dependencies?: string[];
  productCodes?: string[];
  order: number;
  hasCustomAckMessaging?: boolean;
  customAckMessage?: string;
  customAckButtonTitle?: string;
};

export type AcknowledgementFileViewDefinitionResponse = AcknowledgementDefinitionResponse & {
  location?: string;
};

export type AcknowledgementDataViewFieldDefinition = {
  name: string;
  type: string | 'RadioGroup';
  required: boolean;
  order: number;
};

export type AcknowledgementDataViewRadioGroupFieldDefinition = {
  label: string;
  yesLabel: string;
  noLabel: string;
  yesText: string;
  noText: string;
  doesSetIconOnChange?: boolean;
  yesIcon?: string;
  noIcon?: string;
} & AcknowledgementDataViewFieldDefinition;

export type AcknowledgementDataViewDefinitionResponse = AcknowledgementDefinitionResponse & {
  fields?: AcknowledgementDataViewFieldDefinition[];
  providesCustomAckIcon?: boolean;
};

export enum DisclosuresSubmitStatus {
  NotRunning,
  Running,
  Passed,
  Failed,
}

export enum DisclosureModalStatus {
  NotShown,
  SubmitDisclosures,
  AccountCreation,
  CreateCredentials,
  ConfirmationPending,
  RedirectToIbxLogin,
  RedirectToFinishApp,
}

export interface DisclosureInfoState {
  acknowledgements: BundleAcknowledgments;
  disclosuresSubmitStatus: DisclosuresSubmitStatus;
  selectedAcknowledgement: Acknowledgement | null;
  token?: string | null;
  isUmg: boolean;
  loginComplete: boolean;
  viewedAcknowledgements: Record<string, boolean>;
  notAcceptedAcknowledgements: Record<string, Acknowledgement>;
  dataViewAcknowledgementFieldValues: Record<string, any>;
  allowsContinue: boolean;
  customDataViewAcknowledgementAcceptedIcon: Record<string, string>;
}

export interface AcknowledgementActionPayload {
  acknowledgement: Acknowledgement;
}

export interface DisclosureInfoPayload extends Record<string, any> {}

export interface AcknowledgmentListProps {
  acknowledgements: Acknowledgement[];
  selectHandler: (acknowledgement: any) => void;
  selectedAcknowledgement: Acknowledgement | null;
}

export interface AcknowledgementHeaderProps {
  header: string;
}

export interface BundleAcknowledgments {
  [key: string]: Acknowledgement[];
}
