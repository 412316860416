import React, { FC } from 'react';
import { DownloadAction } from '../DownloadAction/DownloadAction';
import { PrintAction } from '../PrintAction/PrintAction';
import { useBreakpoint } from '@laurelroad/lrds-react';

export const AcknowledgementRowActions: FC<{ location?: string; displayName?: string }> = ({
  location,
  displayName,
}) => {
  const isDesktopViewMode = useBreakpoint() !== 'mobile';
  return (
    <>
      <DownloadAction href={location} download={`${displayName}.pdf`} />
      {isDesktopViewMode && <PrintAction location={location} />}
    </>
  );
};
