import { PDFFileViewerContextProps } from '../../../types';
import { MutableRefObject } from 'react';

export const useFitToWidthHandler = (
  pdfViewerCtxHolder: MutableRefObject<PDFFileViewerContextProps>
) => {
  return () => {
    const { setScale } = pdfViewerCtxHolder.current;
    setScale(1, pdfViewerCtxHolder.current?.viewerContainer?.clientWidth);
  };
};
