import React from 'react';
import { FC } from 'react';

export const DisclosuresTitle: FC = () => {
  return (
    <div className="disclosures__title u-flex u-flex--column">
      <h2 className="disclosures__title__heading">To proceed, please read and agree to our Disclosures and Policies.</h2>
      <h5 className="disclosures__title__subheading lrds-text" data-color="secondaryText">
        You can print to PDF to download a copy of these disclosures for your records.
      </h5>
    </div>
  );
};
