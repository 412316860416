import React, { FC, memo } from 'react';
import { Button, Optional } from '@laurelroad/lrds-react';
import { DisclosuresFooterProps } from './types';
import './disclosure-footer.scss';
import { useDisclosureInfo } from '../hooks';
import { useIsCheckingApplication } from '../../../hooks/useIsCheckingApplication';

const DisclosuresFooter: FC<DisclosuresFooterProps> = ({ onCancel, onSubmit }) => {
  const { acknowledgements, allowsContinue } = useDisclosureInfo();
  const isChecking = useIsCheckingApplication();

  return (
    <Optional renderIf={Object.keys(acknowledgements).length > 0}>
      <div className={'disclosures__footer'}>
        <div
          className="hys-text bold u-flex u-flex--end u-l-full-width"
          style={{ display: 'flex' }}>
          <Optional renderIf={isChecking && allowsContinue}>
            <span className={'disclosures__footer__agree-submit-message'}>
              By clicking "Agree & Continue,” you are agreeing to open a checking account with
              Laurel Road
            </span>
          </Optional>
        </div>
        <div className="disclosures__footer__buttons">
          <Button type={'secondary'} onClick={onCancel}>
            Back
          </Button>
          <Button type={'primary'} onClick={onSubmit} isDisabled={!allowsContinue}>
            Agree & Continue
          </Button>
        </div>
      </div>
    </Optional>
  );
};

export default memo(DisclosuresFooter);
