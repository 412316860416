import { AppDispatch, AppState } from '../../store';
import { hideBundleToolTip } from '../BundleModal/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, putRequest } from '../../utils/crud';
import { FETCH_DISCLOSURES_ENDPOINT, SUBMIT_DISCLOSURES_ENDPOINT } from './constants';
import {
  Acknowledgement,
  AcknowledgementDataViewDefinitionResponse,
  AcknowledgementFileViewDefinitionResponse,
  BundleAcknowledgments,
  DisclosureInfoPayload,
} from './types';
import { ApplicationUpdatedResponse } from '../../types/ApplicationUpdatedResponse';

import { postMultiproductInfo } from '../ProfileCreationPage/asyncActions';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { convertFetchDisclosuresToAcknowledgements, validateAcknowledgement } from './utils';
import { acceptAcknowledgement } from './slice';

export const fetchDisclosuresActionPrefix = 'disclosureInfo/fetchDisclosures';
export const submitDisclosuresActionPrefix = 'disclosureInfo/submitDisclosures';

const groupAcknowledgmentsByProductCode = (
  acknowledgements: (
    | AcknowledgementFileViewDefinitionResponse
    | AcknowledgementDataViewDefinitionResponse
  )[]
) => {
  return convertFetchDisclosuresToAcknowledgements(acknowledgements).reduce(
    (acc, ack) => {
      if (ack.productCodes?.length) {
        ack.productCodes.reduce((_acc, pr) => {
          _acc[pr] = (_acc[pr] || []).concat(ack);
          return _acc;
        }, acc);
      } else {
        acc.noCode = acc.noCode.concat(ack);
      }
      return acc;
    },
    {
      noCode: [],
    } as BundleAcknowledgments
  );

};

const executeFetchDisclosures = async () =>
  await getRequest<Acknowledgement[]>(`${FETCH_DISCLOSURES_ENDPOINT}`);

type SubmitDisclosuresReturnType = ApplicationUpdatedResponse & {
  token: string;
  umg: boolean;
  loginComplete: boolean;
};
export const executeSubmitDisclosures = async (
  _: void,
  { getState, dispatch }: BaseThunkAPI<AppState, unknown, AppDispatch>
): Promise<SubmitDisclosuresReturnType> => {
  const { dataViewAcknowledgementFieldValues } = getState().disclosureInfo;
  const result = await putRequest<DisclosureInfoPayload, SubmitDisclosuresReturnType>(
    SUBMIT_DISCLOSURES_ENDPOINT,
    dataViewAcknowledgementFieldValues
  ).catch(
    () =>
      ({
        umg: getState().disclosureInfo.isUmg || false,
        loginComplete: false,
        token: getState().disclosureInfo.token,
      }) as SubmitDisclosuresReturnType
  );

  if (getState().bundle.productCodes.length > 0) {
    dispatch(postMultiproductInfo());
  }
  dispatch(hideBundleToolTip());
  return result;
};

export const fetchDisclosures = createAsyncThunk<
  { acknowledgements: BundleAcknowledgments; productCodes: string[] },
  void,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(
  fetchDisclosuresActionPrefix,
  async (_: void, { getState }: BaseThunkAPI<AppState, unknown, AppDispatch>) => {
    const disclosures = await executeFetchDisclosures();

    const productCodes = [
      getState().application.productCode,
      ...getState().bundle.productCodes,
    ];

    return { acknowledgements: groupAcknowledgmentsByProductCode(disclosures), productCodes };
  }
);

export const submitDisclosures = createAsyncThunk<
  SubmitDisclosuresReturnType,
  void,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(submitDisclosuresActionPrefix, executeSubmitDisclosures);

export const acceptValidatedAcknowledgement = createAsyncThunk<
  void,
  void,
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>('disclosureInfo/acknowledge', (_, { getState, dispatch }) => {
  const {
    disclosureInfo: { selectedAcknowledgement, dataViewAcknowledgementFieldValues },
  } = getState();

  if (selectedAcknowledgement) {
    if (selectedAcknowledgement?.type === 'DataView') {
      const validationResult = validateAcknowledgement(
        selectedAcknowledgement,
        dataViewAcknowledgementFieldValues
      );

      if (!validationResult) return;
    }
    dispatch(acceptAcknowledgement({ acknowledgement: selectedAcknowledgement }));
  }
});
