import React, { FC } from 'react';
import { Icon } from '@laurelroad/lrds-react';

export const DownloadAction: FC<{ href?: string; download?: string }> = ({
  href,
  download,
}) => {
  return (
    <a
      href={href}
      download={download}
      className={'high-yield-savings__download-button'}
      rel="noopener noreferrer"
      target="_blank">
      <Icon className="common-icon fa-lg" icon={'check'} style={{ maxWidth: '16px' }} />
    </a>
  );
};
