import { FormEvent, useCallback, useEffect, useRef, useState } from 'react';

export const useToolbarPageInput = (numPages: number) => {
  const pageChangeTimeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    return () => {
      if (pageChangeTimeoutRef.current) clearTimeout(pageChangeTimeoutRef.current);
    };
  }, []);

  const [pageIndex, setPageIndex] = useState<number>(1);

  useEffect(() => {
    if (pageChangeTimeoutRef.current) clearTimeout(pageChangeTimeoutRef.current);
    pageChangeTimeoutRef.current = setTimeout(() => {
      sourceOfPageIndIsChangeHandlerRef.current = false;
    }, 50);
  }, [pageIndex]);

  const sourceOfPageIndIsChangeHandlerRef = useRef(false);

  const onPageInputChange = useCallback(
    ({ currentTarget: { value = '' } }: FormEvent<HTMLInputElement>) => {
      if (pageChangeTimeoutRef.current) clearTimeout(pageChangeTimeoutRef.current);

      if (value === '') {
        sourceOfPageIndIsChangeHandlerRef.current = true;
        setPageIndex(0);
        return;
      }

      const numberValue = Number(value);
      if (Number.isNaN(numberValue)) {
        return;
      }

      sourceOfPageIndIsChangeHandlerRef.current = true;

      if (numberValue <= 0) {
        setPageIndex(1);
      } else if (numberValue > numPages) {
        setPageIndex(numPages);
      } else {
        setPageIndex(numberValue);
      }
    },
    [numPages]
  );

  const isPageIndInputSupplier = useCallback(() => {
    return sourceOfPageIndIsChangeHandlerRef.current;
  }, []);

  return {
    pageIndex,
    isPageIndSourceUserInput: isPageIndInputSupplier,
    setPageIndex,
    onPageInputChange,
  };
};
