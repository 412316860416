import React, { FC } from 'react';
import { Acknowledgement } from '../../types';
import '../AcknowledgementViewer/acknowledgement-viewer.scss';
import { Drawer } from '@laurelroad/lrds-react';
import './styles.scss';
import { AcknowledgementViewerAccept } from '../AcknowledgmentViewerAccept/AcknowledgementViewerAccept';
import { acknowledgementViewerComponentFactory } from '../acknowledgementViewerComponentFactory';
import { useDataViewInputContext } from '../../hooks/useDataViewInputContext';
import {useDrawerControl} from "./hooks/useDrawerControl";

const AcknowledgementViewerMobile: FC<{
  acknowledgement: Acknowledgement | null;
}> = ({ acknowledgement }) => {

  const [isOpen, close] = useDrawerControl();

  const { setCustomAckIcon, setDataViewFieldValue, fieldValues } = useDataViewInputContext();

  return (
    <Drawer
      position={'bottom'}
      isOpen={isOpen}
      style={{ height: '70vh' }}
      className="disclosures__accept-disclosure-drawer">
      <div
        className="u-flex u-flex--column u-flex--middle u-l-full-width"
        style={{ flex: 'auto' }}>
        <div className="u-flex u-flex--column u-l-full-width disclosures__accept-disclosure-drawer__title-container">
          <div
            className="u-flex"
            style={{
              flex: 'auto',
              justifyContent: `${
                acknowledgement?.type === 'FileView' ? 'space-between' : 'flex-end'
              }`,
            }}>
            {acknowledgement?.type === 'FileView' && (
              <a href={acknowledgement?.location} rel="noopener noreferrer" target="_blank">
                <img src="/images/open.svg" alt="*" />
              </a>
            )}
            <a onClick={close} style={{ cursor: 'pointer' }}>
              <img src="/images/exit.svg"  alt="*"/>
            </a>
          </div>
          <div className="disclosures__accept-disclosure-drawer__title-container__disclosure-name lrds-paragraph__header--upper">
            {acknowledgement?.displayName}
          </div>
        </div>
        {acknowledgementViewerComponentFactory(
          acknowledgement,
          'mobile',
          setDataViewFieldValue,
          setCustomAckIcon,
          fieldValues
        )}
        <AcknowledgementViewerAccept />
      </div>
    </Drawer>
  );
};

export default AcknowledgementViewerMobile;
