import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { useCallback } from 'react';
import {
  setCustomDataViewAcknowledgementAcceptedIcon,
  updateDataViewAcknowledgementFieldValues,
} from '../slice';
import { useDisclosureInfo } from './useDisclosureInfo';

export const useDataViewInputContext = () => {
  const dispatch = useDispatch<AppDispatch>();
  const setDataViewFieldValue = useCallback(
    (f: string, v: any) => {
      dispatch(updateDataViewAcknowledgementFieldValues({ field: f, value: v }));
    },
    [dispatch]
  );

  const setCustomAckIcon = useCallback(
    (icon?: string) => {
      dispatch(setCustomDataViewAcknowledgementAcceptedIcon(icon || 'check'));
    },
    [dispatch]
  );

  const fieldValues = useDisclosureInfo().dataViewAcknowledgementFieldValues;

  return { setDataViewFieldValue, setCustomAckIcon, fieldValues };
};
