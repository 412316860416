import React, { FC, FormEventHandler } from 'react';

export const ToolbarPageInput: FC<{
  pageIndex: number;
  numPages: number;
  onPageChange: FormEventHandler<HTMLInputElement>;
}> = ({ pageIndex, numPages, onPageChange }) => {
  return (
    <div className="u-flex oao-pdf-viewer__toolbar__paging">
      <input
        type="text"
        className="lrds-textfield__input"
        value={pageIndex === 0 ? '' : pageIndex?.toString(10)}
        onInput={onPageChange}
      />
      <div>/</div>
      <div>{numPages}</div>
    </div>
  );
};
