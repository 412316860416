import React, { FC } from 'react';
import { Button, useCn } from '@laurelroad/lrds-react';
import { useAcceptAcknowledgement, useDisclosureInfo } from '../../hooks';

export const AcknowledgementViewerAccept: FC = () => {
  const acceptHandler = useAcceptAcknowledgement();
  const { notAcceptedAcknowledgements, selectedAcknowledgement } = useDisclosureInfo();
  const { hasCustomAckMessaging, customAckMessage, customAckButtonTitle } =
    selectedAcknowledgement || {};

  const isDataViewAck = selectedAcknowledgement?.type === 'DataView';

  const classes = useCn(
    'u-l-full-width',
    'u-flex',
    'disclosures__acknowledgement-viewer__footer',
    {
      'disclosures__acknowledgement-viewer__footer--data-view': isDataViewAck,
    }
  );

  return (
    <div className={classes}>
      {notAcceptedAcknowledgements[selectedAcknowledgement?.id || ''] && (
        <>
          {hasCustomAckMessaging === true && (
            <div className="disclosures__title lrds-text" data-color="primary">
              {customAckMessage}
            </div>
          )}
          <Button type={'primary'} onClick={acceptHandler}>
            {hasCustomAckMessaging === true ? customAckButtonTitle : 'Accept'}
          </Button>
        </>
      )}
    </div>
  );
};
