import { useCallback } from 'react';
import { findNextScale } from '../utils';

export const useZoomControls = (scale: number, setScale: (s: number) => void) => {
  const onZoomOut = useCallback(() => {
    setScale(Math.max(findNextScale(scale, false), 0.25));
  }, [scale, setScale]);

  const onZoomIn = useCallback(() => {
    setScale(Math.min(findNextScale(scale, true), 2));
  }, [scale, setScale]);

  const zoom = Math.round(scale * 100);

  return {
    zoom,
    onZoomIn,
    onZoomOut,
  };
};
