import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Acknowledgement,
  AcknowledgementActionPayload,
  DisclosureInfoState,
  DisclosuresSubmitStatus,
} from './types';
import { fetchDisclosures, submitDisclosures } from './asyncActions';
import { isEmpty } from 'lodash';
import { filterAllGroupedByProductToBySelectedProduct } from './utils';
import { changeBundleProducts } from '../BundleModal/asyncActions';

export const initialState: DisclosureInfoState = {
  acknowledgements: {},
  disclosuresSubmitStatus: DisclosuresSubmitStatus.NotRunning,
  selectedAcknowledgement: null,
  isUmg: false,
  loginComplete: false,
  notAcceptedAcknowledgements: {},
  viewedAcknowledgements: {},
  dataViewAcknowledgementFieldValues: {},
  allowsContinue: false,
  customDataViewAcknowledgementAcceptedIcon: {},
};

const selectNextAckToAccept = (
  notAcceptedAcknowledgements: DisclosureInfoState['notAcceptedAcknowledgements']
) => {
  return Object.values(notAcceptedAcknowledgements).find(e => {
    return !(e.dependencies || []).some(d => notAcceptedAcknowledgements[d] !== undefined);
  });
};

const disclosureInfoSlice = createSlice({
  name: 'disclosureInfo',
  initialState,
  reducers: {
    selectAcknowledgement: (state, action: PayloadAction<AcknowledgementActionPayload>) => ({
      ...state,
      selectedAcknowledgement: action.payload.acknowledgement,
      viewedAcknowledgements: {
        ...state.viewedAcknowledgements,
        [action.payload.acknowledgement.id]: true,
      },
    }),
    viewedAcknowledgement: (state, action: PayloadAction<AcknowledgementActionPayload>) => {
      const { acknowledgement } = action.payload;
      return {
        ...state,
        viewedAcknowledgements: {
          ...state.viewedAcknowledgements,
          [acknowledgement.id]: true,
        },
      };
    },
    updateDataViewAcknowledgementFieldValues: (
      state,
      { payload: { field, value } }: PayloadAction<{ field: string; value: any }>
    ) => {
      state.dataViewAcknowledgementFieldValues[field] = value;
    },
    setCustomDataViewAcknowledgementAcceptedIcon: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      if (state.selectedAcknowledgement && state.selectedAcknowledgement.type === 'DataView')
        state.customDataViewAcknowledgementAcceptedIcon[state.selectedAcknowledgement.id] =
          payload;
    },
    acceptAcknowledgement: (state, action: PayloadAction<AcknowledgementActionPayload>) => {
      const { acknowledgement } = action.payload;

      const notAcceptedAcknowledgements = {
        ...state.notAcceptedAcknowledgements,
      };

      delete notAcceptedAcknowledgements[acknowledgement.id];

      // validate all and update allowsContinue

      let nextAck =
        // take into account it could be ack that has a dependency not accepted before i can select it!

        selectNextAckToAccept(notAcceptedAcknowledgements) || acknowledgement;

      return {
        ...state,
        notAcceptedAcknowledgements: notAcceptedAcknowledgements,
        selectedAcknowledgement: nextAck,
        allowsContinue:
          !isEmpty(state.acknowledgements) && isEmpty(notAcceptedAcknowledgements),
      } as DisclosureInfoState;
    },
  },
  extraReducers: builder => {
    builder.addCase(
      changeBundleProducts.fulfilled,
      (state, { payload: { previousProducts, currentProducts } }) => {
        if (isEmpty(state.acknowledgements)) return state;

        const filteredPreviousAcknowledgements = filterAllGroupedByProductToBySelectedProduct(
          state.acknowledgements,
          previousProducts
        );

        const previouslyAccepted = Object.values(filteredPreviousAcknowledgements)
          .flat()
          .reduce(
            (acc, ack) => {
              if (state.notAcceptedAcknowledgements[ack.id] === undefined) {
                acc[ack.id] = true;
              }
              return acc;
            },
            {} as Record<string, boolean>
          );

        const filteredAcknowledgements = filterAllGroupedByProductToBySelectedProduct(
          state.acknowledgements,
          currentProducts
        );

        let selectedAckValid = false;
        const notAcceptedAcknowledgements = Object.keys(filteredAcknowledgements).reduce(
          (acc, product) => {
            filteredAcknowledgements[product].reduce((prAcc, nextAck) => {
              if (state.selectedAcknowledgement?.id === nextAck.id) {
                selectedAckValid = !nextAck.dependencies || nextAck.dependencies.length < 1;
              }

              if (previouslyAccepted[nextAck.id]) {
                return prAcc;
              }
              prAcc[nextAck.id] = nextAck;
              return prAcc;
            }, acc);
            return acc;
          },
          {} as Record<string, Acknowledgement>
        );

        return {
          ...state,
          notAcceptedAcknowledgements,
          selectedAcknowledgement: selectedAckValid
            ? state.selectedAcknowledgement
            : selectNextAckToAccept(notAcceptedAcknowledgements) ||
              filteredAcknowledgements[Object.keys(filteredAcknowledgements)[0]]?.[0],
          allowsContinue: isEmpty(notAcceptedAcknowledgements),
        } as DisclosureInfoState;
      }
    );
    builder.addCase(
      fetchDisclosures.fulfilled,
      (state: DisclosureInfoState, { payload: { acknowledgements, productCodes } }) => {
        const filteredAcknowledgements = filterAllGroupedByProductToBySelectedProduct(
          acknowledgements,
          productCodes
        );

        const selectedAcknowledgement =
          filteredAcknowledgements[Object.keys(filteredAcknowledgements)[0]]?.[0];

        return {
          ...state,
          acknowledgements: acknowledgements,
          selectedAcknowledgement: selectedAcknowledgement,
          viewedAcknowledgements: {
            ...state.viewedAcknowledgements,
            [selectedAcknowledgement?.id || '']: true,
          },
          notAcceptedAcknowledgements: Object.keys(filteredAcknowledgements).reduce(
            (acc, next) => {
              filteredAcknowledgements[next].reduce((prAcc, nextAck) => {
                prAcc[nextAck.id] = nextAck;
                return prAcc;
              }, acc);
              return acc;
            },
            {} as Record<string, Acknowledgement>
          ),
          allowsContinue: false,
        };
      }
    );
    builder.addCase(submitDisclosures.pending, (state: DisclosureInfoState) => ({
      ...state,
      disclosuresSubmitStatus: DisclosuresSubmitStatus.Running,
    }));
    builder.addCase(
      submitDisclosures.fulfilled,
      (state: DisclosureInfoState, { payload: { token, umg, loginComplete } }) => ({
        ...state,
        disclosuresSubmitStatus: DisclosuresSubmitStatus.Passed,
        token,
        isUmg: umg,
        loginComplete,
      })
    );
  },
});

export const {
  selectAcknowledgement,
  acceptAcknowledgement,
  viewedAcknowledgement,
  updateDataViewAcknowledgementFieldValues,
  setCustomDataViewAcknowledgementAcceptedIcon,
} = disclosureInfoSlice.actions;
export const { reducer: DisclosureInfoReducer } = disclosureInfoSlice;
