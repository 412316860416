import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, AppState } from '../../store';
import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk';
import { optInHandler, optOutHandler } from './slice';

export const changeBundleProducts = createAsyncThunk<
  { previousProducts: string[]; currentProducts: string[] },
  { operation: 'in' | 'out'; productCode: string },
  { state: AppState; dispatch: AppDispatch; rejectValue: undefined }
>(
  'bundle/changeProducts',
  (
    { operation, productCode },
    { getState, dispatch }: BaseThunkAPI<AppState, unknown, AppDispatch>
  ) => {
    const previousProducts = [
      getState().application.productCode,
      ...getState().bundle.productCodes,
    ];
    dispatch(operation === 'in' ? optInHandler(productCode) : optOutHandler(productCode));
    return {
      currentProducts: [getState().application.productCode, ...getState().bundle.productCodes],
      previousProducts,
    };
  }
);
