import React, { FC, memo, useEffect } from 'react';
import { useSubmitDisclosures, useTransitionToPersonalInfoPage } from './hooks';
import DisclosuresModal from './DisclosuresModal/DisclosuresModal';
import DisclosuresFooter from './DisclosuresFooter/DisclosuresFooter';
import DisclosuresBody from './DisclosuresBody/DisclosuresBody';
import './styles.scss';
import { useSendDisclosuresEvent } from '../../services/google-tag-manager/useSendDisclosuresEvent';
import { useSendMultiproductAppCreateEvent } from '../../services/google-tag-manager/useSendMultiproductAppCreatedEvent';
import { useSendMultiproductPasInfo } from '../App/hooks/useSendMultiProductPasInfo';
import { fetchDisclosures } from './asyncActions';
import { AppDispatch } from '../../store';
import { DisclosuresTitle } from './DisclosuresTitle/DisclosuresTitle';
import { useDispatch } from 'react-redux';

const DisclosuresPage: FC = () => {
  useSendDisclosuresEvent();
  useSendMultiproductAppCreateEvent();
  useSendMultiproductPasInfo();
  const submitHandler = useSubmitDisclosures();
  const cancelHandler = useTransitionToPersonalInfoPage();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchDisclosures());
  }, [dispatch]);

  return (
    <div className="oao__form disclosures" data-testid="disclosures-page">
      <div className={'oao__form__body'}>
        <DisclosuresModal />
        <DisclosuresTitle />
        <DisclosuresBody />
        <DisclosuresFooter onSubmit={submitHandler} onCancel={cancelHandler} />
      </div>
    </div>
  );
};

export default memo(DisclosuresPage);
