import React, { useState } from 'react';
import { FC } from 'react';
import { PDFViewerObserver } from '../../observer';
import { Download } from './components/Download/Download';
import { Menu } from './components/Menu/Menu';
import { PDFFileViewerContextProps } from '../../types';
import { ToolbarPageInput } from './components/ToolbarPageInput/ToolbarPageInput';
import { ToolbarZoomControl } from './components/ToolbarZoomControl/ToolbarZoomControl';
import './styles.scss';
import { FitToWidth } from './components/FitToWidth/FitToWidth';
import { useToolbarPageInput } from './hooks/useToolbarPageInput';
import { useScrollOnPageChangeFromPageInput } from './hooks/useScrollOnPageChangeFromPageInput';
import { useScrollListenerToSetPage } from './hooks/useScrollListenerToSetPage';
import { useSubscribeToPDFFileViewer } from './hooks/useSubscribeToPDFFileViewer';
import { useFitToWidthHandler } from './hooks/useFitToWidthHandler';

export const PDFViewerToolbar: FC<{
  viewerObserver: PDFViewerObserver<PDFFileViewerContextProps>;
}> = ({ viewerObserver }) => {
  const [numPages, setNumPages] = useState(0);

  const { pageIndex, setPageIndex, onPageInputChange, isPageIndSourceUserInput } =
    useToolbarPageInput(numPages);

  const { _scale, _pdfUrl, pdfViewerCtxHolder } = useSubscribeToPDFFileViewer(
    viewerObserver,
    setNumPages,
    pageIndex
  );

  useScrollListenerToSetPage(pdfViewerCtxHolder, setPageIndex, isPageIndSourceUserInput);

  useScrollOnPageChangeFromPageInput(pageIndex, pdfViewerCtxHolder, isPageIndSourceUserInput);

  const fitToWidthOnClick = useFitToWidthHandler(pdfViewerCtxHolder);

  return (
    <div className="u-flex u-flex--between oao-pdf-viewer__toolbar">
      <Menu
        pageIndex={pageIndex}
        numPages={numPages}
        onPageChange={onPageInputChange}
        _pdfUrl={_pdfUrl}
        scale={_scale}
        setScale={pdfViewerCtxHolder.current.setScale}
        pdfViewerCtxHolder={pdfViewerCtxHolder}
      />

      <div className="u-flex" style={{ columnGap: '10px', justifyContent: 'space-evenly' }}>
        <ToolbarPageInput
          pageIndex={pageIndex}
          numPages={numPages}
          onPageChange={onPageInputChange}
        />

        <ToolbarZoomControl scale={_scale} setScale={pdfViewerCtxHolder.current.setScale} />

        <FitToWidth onClick={fitToWidthOnClick} />
      </div>
      <div className="u-flex" style={{ cursor: 'pointer' }}>
        <a rel="noreferrer" download href={_pdfUrl} role={'button'} target={'_blank'}>
          <Download />
        </a>
      </div>
    </div>
  );
};
