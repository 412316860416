import { pdfjs } from 'react-pdf';
import { PDFFileViewerContextProps } from './types';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const PDF_DOC_OPTIONS = {
  cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps/`,
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

export const INITIAL_PDF_VIEWER_STATE: Readonly<PDFFileViewerContextProps> = {
  setScale: () => {},
  totalPages: 0,
  pagesInfo: [],
  viewerContainer: document.createElement('div'),
};

export const INIT_PAGE_DIMENSIONS = {
  width: undefined,
  scale: 1,
};

export const AVAILABLE_SCALES: Readonly<number[]> = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
