import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { printFile } from '../../../../../../../utils/print.file';

export const PrintAction: FC<{ location?: string }> = ({ location }) => {
  return (
    <div onClick={() => location && printFile(location)} className="u-flex--inline">
      <img src={'/images/printer.svg'} />
    </div>
  );
};
