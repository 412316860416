import React, {
  ChangeEventHandler,
  createContext,
  FC,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Acknowledgement, AcknowledgementDataViewRadioGroupFieldDefinition } from '../types';
import { FileAcknowledgementViewer } from './AcknowledgementViewer/components/FileAcknowledgementViewer/FileAcknowledgementViewer';
import { PDFFileViewer } from '../../PdfFileViewer/PDFFileViewer';

const AckFieldComponentDataInputContext = createContext({
  setFieldValue: (_f: string, _v: any) => {},
  setCustomAckIcon: (_icon?: string) => {},
  fieldValues: {} as Readonly<Record<string, any>>,
});

const AcknowledgementDataViewRadioGroupField: FC<
  AcknowledgementDataViewRadioGroupFieldDefinition
> = ({
  name,
  label,
  noLabel,
  yesLabel,
  noText,
  yesText,
  doesSetIconOnChange,
  noIcon,
  yesIcon,
}) => {
  const { setFieldValue, setCustomAckIcon, fieldValues } = useContext(
    AckFieldComponentDataInputContext
  );

  const [radioSelection, setRadioSelection] = useState<boolean | undefined>(fieldValues[name]);

  const radioSelectionHandler = useCallback<ChangeEventHandler<HTMLInputElement>>(
    ({ currentTarget: { value } }) => {
      setRadioSelection(value === 'true');
      setFieldValue(name, value === 'true');
      if (doesSetIconOnChange) {
        setCustomAckIcon(value === 'true' ? yesIcon : noIcon);
      }
    },
    [doesSetIconOnChange, name, noIcon, setCustomAckIcon, setFieldValue, yesIcon]
  );

  return (
    <div className="disclosures__acknowledgement-viewer__radio-group-field">
      <span
        className="disclosures__acknowledgement-viewer__radio-group-field__message"
        dangerouslySetInnerHTML={{ __html: label }}
      />
      <div className="lrds-radio-group" style={{ padding: '16px 16px 8px 16px' }}>
        <label className="lrds-radio" htmlFor={`${name}_No`}>
          <input
            type="radio"
            onChange={radioSelectionHandler}
            id={`${name}_No`}
            name={name}
            value={'false'}
            checked={radioSelection === false}
          />
          <span>
            <h5>{noLabel}</h5>
          </span>
          <p className="lrds-text" data-color="secondaryText">
            {noText}
          </p>
        </label>
        <label className="lrds-radio" htmlFor={`${name}_Yes`}>
          <input
            type="radio"
            onChange={radioSelectionHandler}
            id={`${name}_Yes`}
            name={name}
            value={'true'}
            checked={radioSelection === true}
          />
          <span>
            <h5>{yesLabel}</h5>
          </span>
          <p className="lrds-text" data-color="secondaryText">
            {yesText}
          </p>
        </label>
      </div>
    </div>
  );
};

const DataViewAcknowledgementFieldContainer: FC<{
  ack: Acknowledgement;
}> = ({ ack }) => {
  return (
    <div className="disclosures__acknowledgement-viewer__content-container">
      {ack.fields?.map(f =>
        f.type === 'RadioGroup' ? (
          <AcknowledgementDataViewRadioGroupField
            key={f.name}
            {...(f as AcknowledgementDataViewRadioGroupFieldDefinition)}
          />
        ) : (
          <div key={f.name} />
        )
      )}
    </div>
  );
};

export const acknowledgementViewerComponentFactory = (
  ack: Acknowledgement | null,
  mode: 'mobile' | 'desktop',
  setFieldValue: (f: string, v: any) => void,
  setCustomAckIcon: (icon?: string) => void,
  fieldValues: Readonly<Record<string, any>>
) => {
  if (ack?.type === 'FileView') {
    if (mode === 'mobile') {
      return <PDFFileViewer file={ack.location || ''} />;
    }
    return <FileAcknowledgementViewer acknowledgement={ack} />;
  } else if (ack?.type === 'DataView') {
    return (
      <AckFieldComponentDataInputContext.Provider
        value={{ setFieldValue, setCustomAckIcon, fieldValues }}>
        <DataViewAcknowledgementFieldContainer ack={ack} />
      </AckFieldComponentDataInputContext.Provider>
    );
  }

  return null;
};
